@charset "utf-8";

/* ----------------------------------------------------------------
   theme/collection
/  --------------------------------------------------------------*/

.collection-section {
  &__detail {
    overflow: hidden;
    color: #8c8c8c;
  }
  &__text {
  }
  &__img {
    float: left;
    margin-right: 30px;
  }
  .title-l4-3 {
    margin-bottom: 20px;
  }
@include media(sp) {
  &__img {
    float: none;
    margin-right: 0;
    text-align: center;
  }
}
}

