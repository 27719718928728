@charset "utf-8";

/* ----------------------------------------------------------------
   theme/archive
/  --------------------------------------------------------------*/

.-archive {
  .content__inner {
    position: relative;
    width: 88%;
    margin: 0 auto;
    @include media(sp) {
      width: auto;
    }
    @media screen and (min-width: 1440px) {
      width: 1000px;
    }
    &.-column {
      width: auto;
    }
  }
  .contents-inner {
    //width: auto;
  }

}

.archive {
  margin-top: 100px;
  margin-bottom: 100px;
  color: #8c8c8c;
  .contents-inner {
    position: relative;
  }
  &-section {
    display: flex;
    padding: 50px 0;
    border-top: 1px solid #dce6e6;
    font-size: rem(12);
    &__detail {
      order: 1;
      width: 100%;
    }
    &__status {
      display: inline-block;
      margin-bottom: 30px;
      padding: 7px 20px;
      line-height: 1;
      color: #00c3b4;
      border: 1px solid #00c3b4;
      font-size: rem(14);
      text-align: center;
      &.-active {
        background: #00c3b4;
        color: #fff;
      }
      &.-next {
        border-color: #009944;
        color: #009944;
      }
      &.-hereafter {
        border-color: #00A0E9;
        color: #00A0E9;
      }
      &.-end {
        border-color: #000000;
        color: #000000;
      }
      &.-information {
        border-color: #1D2088;
        color: #1D2088;
      }
      &.-wanted {
        border-color: #920783;
        color: #920783;
      }
      &.-wanted-end {
        border-color: #E60012;
        color: #E60012;
      }
    }
    &__title {
      margin: 0;
      padding-bottom: 20px;
      font-size: rem(24);
      color: #3b5b82;
      border-bottom: 1px solid #dce6e6;
      &:before {
        display: block;
        position: relative;
        content: " ";
        width: 74px;
        height: 4px;
        margin-bottom: 10px;
        background: #00c3b4;
      }
    }
    &__date {
      padding: 10px 0;
      font-weight: bold;
      border-bottom: 1px solid #dce6e6;
    }
    &__category {
      display: flex;
      list-style: none;
      margin: 0 0 30px;
      padding: 0;
      li {
        &:after {
          content: "｜";
        }
        &:last-child:after {
          display: none;
        }
      }
    }
    &__img {
      flex: 0 0 400px;
      margin-right: 40px;
      order: 0;
      img {
        border: 1px solid #dce6e6;
      }
    }
    &__link {
      margin: 90px 0 0;
      text-align: right;
      a {
        display: inline-block;
        padding: 10px 15px 10px 55px;
        background: #0fb586;
        color: #fff;
        &:after {
          margin-left: 20px;
          background-image: url(../img/share/icon_link_fff.png);
        }
      }
    }
  }
  &.shop {
    .archive-section {
      display: block;
      &__header {
        display: flex;
        margin-bottom: 50px;
      }
      &__title {
        border: 0;
      }
      &__category {
        margin-top: -10px;
      }
      &__table {
        width: 100%;
        margin: 10px 0 30px;
        margin-bottom: 2em;
        border-collapse: collapse;
        th,td {
          padding: 10px 15px;
          
        }
        th {
          width: 20%;
          color: #fff;
          background: #53719d;
          border-bottom: 1px solid #fff;
          font-weight: normal;
        }
        td {
          border-bottom: 1px solid #c7c7c7;
        }
        tr:last-child th {
          border-bottom: 1px solid #53719d;
        }
      }
      &__img {
        &-main {
          list-style: none;
          margin: 0 0 15px;
          padding: 0;
          li {
            display: none;
            width: 400px;
            height: 284px;
            background: #f5f9f9;
            border: 1px solid #dce6e6;
            text-align: center;
            overflow: hidden;
            &:first-child {
              display: block;
            }
          }
          img {
            max-height: 100%;
          }
        }
        &-thumbnail {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          li {
            position: relative;
            width: 76px;
            height: 76px;
            margin-right: 5px;
            border: 1px solid #9aa1a1;
            overflow: hidden;
            &:last-child {
              margin: 0;
            }
            &:after {
              position: absolute;
              top: 0;
              display: block;
              content: " ";
              width: 76px;
              height: 76px;
              background: rgba(128,128,128,.6);
              mix-blend-mode: multiply;
            }
          }
          img {
            position: relative;
          }
        }
      }
      &__text {
        width: 100%;
      }
      &__link {
        margin-top: 30px;
      }
    }
  }
@include media(sp) {
  margin: 0;
  &-section {
    padding: 6% 0;
    flex-direction: column;
    &:first-child {
      border: 0;
    }
    &__detail {
      order: 1;
    }
    &__img {
      flex: 0 0 100%;
      margin: 0 0 6%;
    }
    &__link {
      margin: 20px 0 0;
    }
  }
  &.shop {
    .archive-section {
      &__header {
        flex-direction: column;
        margin-bottom: 20px;
      }
      &__detail {
        order: 0;
      }
      &__table {
        th,td {
          padding: 5px 10px;
        }
        th {
          width: 30%;
        }
      }
      &__img {
        order: 1;
        &-main {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}
}

.pagination {
  list-style: none;
  margin: 0;
  padding: 50px 0 0;
  display: flex;
  border-top: 1px solid #dce6e6;
  justify-content: center;
  li {
    margin-right: 5px;
    font-size: rem(19);
    color: #284e84;
    span,a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 1;
      border: 1px solid #284e84;
      border-radius: 4px;
    }
    span {
      background: #284e84;
      color: #fff;
    }
    a {
      background: #fff;
      color: #284e84;
    }
    .link_before {
      font-size: 0;
      &:after {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f104";
        font-size: 40px;
      }
    }
    .link_next {
      font-size: 0;
      &:after {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f105";
        font-size: 40px;
      }
    }
  }
@include media(sp) {
  flex-wrap: wrap;
  li {
    margin-bottom: 5px;
    font-size: rem(14);
    span,a {
      width: 30px;
      height: 30px;
    }
    .link_before {
      &:after {
        font-size: 20px;
      }
    }
    .link_next {
      &:after {
        font-size: 20px;
      }
    }
  }
}
}

.-note {
  .archive-section__title {
    border: 0;
  }
  .archive-section__date {
    font-weight: normal;
    border-top: 1px solid #dce6e6;
  }
}

.-info {
  .archive-section__detail {
    width: 100%;
  }
  .archive-section__date {
    font-weight: normal;
  }
}

.-place {
  .archive-section__title {
    border: 0;
  }
  .archive-section__table {
    width: 100%;
    margin: 10px 0 30px;
    margin-bottom: 2em;
    border-collapse: collapse;
    th,td {
      padding: 10px 15px;
      
    }
    th {
      //width: 20%;
      color: #fff;
      background: #53719d;
      border-bottom: 1px solid #fff;
      font-weight: normal;
    }
    td {
      border-bottom: 1px solid #c7c7c7;
    }
    tr:last-child th {
      border-bottom: 1px solid #53719d;
    }
  }
  .-entry {
    .archive-section {
      &__detail {
        width: 100%;
      }
      &__category {
        padding: 10px 0;
        border-top: 1px solid #dce6e6;
        border-bottom: 1px solid #dce6e6;
      }
      &-tablearea {
        padding: 28px 28px 18px;
        background: #f5f9f9;
      }
      &__table {
        margin: 0;
        th,td {
          border-bottom: 10px solid #f5f9f9;
        }
        th {
          width: 15%;
        }
      }
    }
  }
@include media(sp) {
  .-entry {
    .archive-section {
      &-tablearea {
        padding: 15px 15px 0;
        background: #f5f9f9;
      }
      &__table {
        margin: 0;
        th,td {
          display: block;
          border: 0;
        }
        th {
          padding: 5px 10px;
        }
        td {
          padding-bottom: 15px;
          padding-left: 0;
        }
      }
    }
  }
}
}

