@charset "utf-8";

/* ----------------------------------------------------------------
   theme/about
/  --------------------------------------------------------------*/

.-about {
  .title-l4-2 {
    margin-bottom: 10px;
  }
  .table table {
    width: 100%;
    th,td {
      width: auto;
      padding: 0;
      background: none;
      color: #787878;
      border: 0;
      text-align: left;
      vertical-align: top;
    }
  }
  .history {
    dt {
      width: 8em;
      padding: 10px 0;
    }
    dd {
      padding: 10px 0;
      padding-left: 8em;
      border-bottom: 1px solid #edefef;
    }
  }
  .section-item {
    margin-bottom: 50px;
  }
  .flex-box {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    &__title {
      margin: 0;
      flex: 0 0 10%;
    }
  }
@include media(sp) {
  .table table {
    th,td {
      display: inline-block;
    }
    th {
      padding: 5px 10px;
      background: #53719d;
      color: #fff;
      line-height: 1;
      & + th {
        margin-left: -10px;
        padding-left: 0px;
      }
    }
    td {
      width: 100%;
      padding-bottom: 10px;
    }
  }
  .flex-box {
    display: block;
    &__title {
      margin-bottom: 1em;
    }
  }
}
}

