@charset "utf-8";

/* ----------------------------------------------------------------
   theme/event
/  --------------------------------------------------------------*/

.event-section {
  position: relative;
  margin-left: 4%;
  padding-left: 4%;
  padding-bottom: 40px;
  border-bottom: 1px solid #dfdfdf;
  .title-l3 {
    width: 65%;
    box-sizing: border-box;
  }
  &__detail {
    display: flex;
    justify-content: space-between;
  }
  &__text {
    width: 65%;
    box-sizing: border-box;
    padding-right: 20px;
  }
  &__date {
    margin-bottom: 0;
  }
  &__img {
    width: 35%;
  }
  &__link {
    display: inline-block;
    position: absolute;
    bottom: 40px;
    margin: 0;
    border: 1px solid #5a5a5a;
    border-radius: 25px;
    a {
      display: inline-block;
      padding: 10px 30px;
      color: #787878;
    }
  }

@include media(sp) {
  margin: 0;
  padding: 0 0 20px;
  .title-l3 {
    width: auto;
  }
  &__detail {
    flex-direction: column;
  }
  &__text {
    width: auto;
    padding: 0;
  }
  &__date {
    margin-bottom: 1em;
  }
  &__img {
    width: auto;
  }
  &__link {
    display: block;
    position: static;
    text-align: center;
  }
}
}

.btn {
  margin: 40px 0 65px;
  text-align: center;
  a {
    position: relative;
    display: block;
    padding: 15px;
    color: #fff;
    border-radius: 3px;
    font-size: rem(17);
    background: #6091cc;
    &:after {
      display: inline-block;
      content: " ";
      position: absolute;
      right: 20px;
      top: 50%;
      width: 22px;
      height: 12.5px;
      margin-top: -6px;
      background-image: url(../img/share/icon_link_fff.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
@include media(sp) {
  a {
    &:after {
      margin-top: -3px;
    }
  }
}
}

.event-swiper {
  margin-top: 60px;
  padding: 20px 0 70px;
  background: #f1f1f1 url(../img/event/bg_hall.png) 0 100px no-repeat;
  background-size: contain;
  .swiper-button-prev {
    left: -5.5%;
    top: 30%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%2300C3B4'%2F%3E%3C%2Fsvg%3E");
  }
  .swiper-button-next {
    right: -5.5%;
    top: 30%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%2300C3B4'%2F%3E%3C%2Fsvg%3E");
  }
  .btn {
    a {
      background: #00c3b4;
    }
  }
  .archive-section__status {
    margin: 20px 0 10px;
  }
@include media(sp) {
  padding: 0;
  overflow: hidden;
}
}

