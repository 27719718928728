@charset "utf-8";

/* ----------------------------------------------------------------
   theme/access
/  --------------------------------------------------------------*/

.access {
  .googlemap {
    position: relative;
    padding-bottom: 16%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  p {
    margin-bottom: 0;
  }
}

