@charset "utf-8";

/* ----------------------------------------------------------------
   theme/introduction
/  --------------------------------------------------------------*/
.-introduction {
main {
  padding-bottom: 0;
}
.section {
  background: #fff;
  &:nth-child(even) {
    background-color: #f1f1f1;
  }
}

.schedule {
  padding: 0 0 70px !important;
  &-table {
    width: 100%;
    margin: 50px 0 120px;
    th,td {
      padding: 10px 20px;
    }
    th {
      display: none;
      background: #53719d;
      color: #fff;
      font-weight: normal;
      border-right: 1px solid #fff;
    }
    tr:first-child th {
      display: table-cell;
    }
    td {
      border: 0;
    }
    tr:nth-child(odd) {
      td {
        background: #f1f1f1;
      }
    }
  }
  @include media(sp) {
    th,td {
      display: block;
      text-align: left;
    }
    tr:first-child {
      display: none;
      th {
        display: none;
      }
    }
  }
}

.exhibition,
.collection,
.gallery {
  position: relative;
  padding: 10px 0 70px;
  background-image: url(../img/introduction/img01.png),url(../img/introduction/img02.png);
  background-repeat: no-repeat;
  background-position: left 80%, right 100px;
  overflow: hidden;
}

.swiper-button-prev {
  left: -5.5%;
  top: 30%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%2300C3B4'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next {
  right: -5.5%;
  top: 30%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%2300C3B4'%2F%3E%3C%2Fsvg%3E");
}

.btn {
  a {
    background: #00c3b4;
  }
}

.contents-box {
  display: flex;
  justify-content: center;
  &-item {
    width: 320px;
    &__title {
      color: #000;
      font-size: rem(16);
    }
    &__text {
      color: #717071;
    }
    & + div {
      margin-left: 30px;
    }
  }
  .archive-section__status {
    margin: 20px 0 10px;
  }
}


.collection {
  background-image: url(../img/introduction/img03.png),url(../img/introduction/img04.png);
  background-position: left 100px, right 80%;
}

.gallery {
  background-image: url(../img/introduction/img05.png),url(../img/introduction/img06.png);
  background-position: 8% 120px, right bottom;
}

.googlecal {
  position: relative;
}

@include media(sp) {

.contents-box {
  flex-direction: column;
  &-item {
    width: auto;
    margin-bottom: 20px;
    & + div {
      margin-left: 0px;
    }
  }
}

.exhibition,
.collection,
.gallery {
  padding: 5px 0 20px;
  &:before ,
  &:after {
    display: none;
  }
}


}


}

