@charset "utf-8";
/* -----------------------------------------------------------
	header
----------------------------------------------------------- */
/* 
	for pc
----------------------------------------------------------- */
@include media(pc) {

.header {
  position: relative;
  padding: 0 45px 40px;
  line-height: 1;
  z-index: 5000;
  background: #fff;
  &.is-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff !important;
  }
  @media screen and (max-width:1024px) {
    padding: 0 20px 20px;
  }
  &__inner {
    position: relative;
    display: flex;
    margin: 0 auto;
    padding: 0 10px;
    justify-content: space-between;
    align-items: end;
    @media screen and (max-width:1024px) {
      padding-bottom: 40px;
    }
  }
  &__logo {
    width: 380px;
    margin: 40px 0 0;
    @media screen and (max-width:1024px) {
      width: 30%;
    }
  }
  a {
    color: #000;
  }
}
.-top .header {
  background: none;
}

.header-menu-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}

.header-menu {
  width: 100%;
}

.header-menu-inner {
  display: flex;
  //flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding: 0 0 0 15px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.header-text-size {
  margin: 0;
  margin-right: 10px;
  padding-right: 20px;
  border-right: 1px solid #939394;
  line-height: 1;
  @media screen and (max-width:1024px) {
    width: 6em;
  }
  dt {
    display: inline-block;
    margin-right: 1em;
    font-size: rem(12);
  }
  dd {
    display: inline-block;
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 1em;
      &:last-child {
        margin: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.header-utility-select {
  border: 0;
  margin-right: 60px;
  @media screen and (max-width:1024px) {
    margin-right: 20px;
  }
}

.display_selectedItem {
  position: relative;
  padding: 7px 15px 7px 5px;
  cursor: default;
  text-transform: uppercase;
  i {
    margin-right: 5px;
  }
  &:after {
    position: absolute;
    content: "\25BC";
    right: 0;
    top: 50%;
    margin-top: -5px;
    font-size: 10px;
  }
}
.select_box {
  position: absolute;
  z-index: 100;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border: solid 1px #bfbfbf;
  text-transform: uppercase;
  li {
    cursor: default;
    padding: 7px 15px 7px 5px;
    &:hover {
      background: #f8f8f8;
    }
  }
  i {
    margin-right: 5px;
  }
  a:hover {
    opacity: 1;
  }
}

.header-utility-nav {
  list-style: none;
  margin: 0 10px 0 0;
  padding: 0;
  font-size: 0;
  line-height: 1;
  li {
    display: inline-block;
    margin-right: 5px;
  }
  a {
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .-youtube {
    width: 35px;
    background-image: url(../img/share/icon_youtube.png);
  }
  .-facebook {
    background-image: url(../img/share/icon_facebook.png);
  }
  .-instagram {
    background-image: url(../img/share/icon_instagram.png);
  }
}

.header-search {
  display: flex;
  width: 20em;
  padding: 15px 20px;
  background: #ECECEC;
  @media screen and (max-width:1024px) {
    width: 14em;
  }
  &__area {
    width: 23.5em;
    width: 93%;
    border: 0;
    color: #A9A9AA;
    background: none;
    font-size: rem(12);
  }
  &__submit {
    width: 21px;
    height: 21px;
    background: url(../img/share/icon_search.png) no-repeat;
    background-size: contain;
    font-size: 0;
    border: 0;
  }
}

.header-nav {
  display: inline-block;
  position: relative;
  text-align: left;
  //background: rgba(255,255,255,1);
  @media screen and (max-width:1024px) {
    position: absolute;
    bottom: 0;
    text-align: right;
    width: 100%;
  }
  &:before {
    display: block;
    position: absolute;
    content: " ";
    width: 105%;
    height: 105%;
    z-index: -1;
    margin-left: -2.5%;
    background: #fff;
    box-shadow:0px 0px 10px 10px #fff;
    filter: blur(10px);
    border-radius: 25px;
  }
  &__title {
    display: none;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      display: inline-block;
      margin-right: 20px;
      padding-right: 25px;
      border-right: 1px solid #000;
      &:last-child {
        margin: 0;
        border: 0;
        padding: 0;
      }
      a {
        display: block;
        line-height: 1.8;
        transition: .5s;
      }
      ul {
        position: absolute;
        width: 240px;
        top: 2.5em;
        left: 70%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 0;
        transition: .2s;
        box-sizing: border-box;
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 1%,rgba(0,0,0,0.12) 25%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
        li {
          display: block;
          margin: 0;
          padding: 0 20px;
          border: 0;
          &:last-child {
            padding: 0 20px;
          }
          a {
            color: #fff;
            line-height: 2;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      &:hover {
        ul {
          overflow: visible;
          height: 100%;
          height: 22em;
        }
      }
    }
  }
}


#sp-menu-close {
  display: none;
}
}

/* 
	for sp
----------------------------------------------------------- */
@include media(tab) {

.header {
  position: relative;
  z-index: 100;
  padding: 15px;
  background: #fff;
  &__logo {
    width: 60%;
    margin: 0;
    line-height: 0;
  }
}


.menu-trigger {
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 27px;
  transition: all .4s;
  box-sizing: border-box;
  //z-index: 100;
  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    border-radius: 4px;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 8px;
    }
    &:nth-of-type(3) {
      top: 16px;
    }
    &:nth-of-type(4) {
      bottom: 0;
    }
  }
  &.open {
    span:nth-of-type(1) {
      transform: translateY(8px) rotate(-45deg);
    }
    span:nth-of-type(2) {
      opacity: 0;
    }
    span:nth-of-type(3) {
      transform: translateY(-8px) rotate(45deg);
    }
    span:nth-of-type(4) {
      display: none;
    }
  }
}

.header-menu-area {
  position: fixed;
  top: 0;
  right: -75%;
  width: 75%;
  border-left: 4px solid #00C9E2;
  height: 100%;
  background: #fff;
  padding-bottom: 15px;
  box-sizing: border-box;
}

.header-menu {
  a {
    color: #B5B5B6;
  }
}

.header-menu-inner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.header-text-size {
  order: 1;
  margin: 0;
  padding-left: 15px;
  border-left: 2px solid #00C9E2;
  line-height: 1;
  dt {
    display: none;
  }
  dd {
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 0.5em;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.header-utility-select {
  
}

.display_selectedItem {
  display: none;
}
.select_box {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1;
  li {
    display: inline-block;
    text-transform: uppercase;
    margin-right: 0.5em;
    &.selected {
      border-bottom: 2px solid #00C9E2;
      a {
        color: #000;
      }
    }
  }
  i {
    display: none;
  }
}

.header-utility-nav {
  position: absolute;
  bottom: 15px;
  right: 0;
  list-style: none;
  margin: 0 10px 0 0;
  padding: 0;
  font-size: 0;
  line-height: 1;
  li {
    display: inline-block;
    margin-right: 5px;
  }
  a {
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .-youtube {
    width: 35px;
    background-image: url(../img/share/icon_youtube.png);
  }
  .-facebook {
    background-image: url(../img/share/icon_facebook.png);
  }
  .-instagram {
    background-image: url(../img/share/icon_instagram.png);
  }
}

.header-search {
  position: absolute;
  bottom: 55px;
  left: 0;
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 2px solid #B5B5B6;
  &__area {
    width: 23.5em;
    width: 93%;
    border: 0;
    font-size: rem(12);
  }
  &__submit {
    width: 21px;
    height: 21px;
    background: url(../img/share/icon_search.png) no-repeat;
    background-size: contain;
    font-size: 0;
    border: 0;
  }
}

.header-nav {
  position: relative;
  text-align: left;
  &__title {
    display: none;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    background-image: linear-gradient(to right, #b5b5b6, #b5b5b6 1px, transparent 1px, transparent 4px);
    background-size: 5px 1px;
    background-position: top;
    background-repeat: repeat-x;
    li {
      background-image: linear-gradient(to right, #b5b5b6, #b5b5b6 1px, transparent 1px, transparent 4px);
      background-size: 5px 1px;
      background-position: bottom;
      background-repeat: repeat-x;
      a {
        position: relative;
        display: block;
        padding: 15px;
        color: #000;
        &:after {
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -12px;
          display: inline-block;
          font-family: FontAwesome;
          content: "\f0da";
          color: #DCDDDD;
        }
      }
      ul {
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}


}