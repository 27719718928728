@charset "utf-8";

/* ----------------------------------------------------------------
   theme/faq
/  --------------------------------------------------------------*/


.faq {
  &-section{
    counter-increment: count;
    //margin-bottom: 40px;
    p {
      margin-bottom: 40px;
    }
  }
  &-title {
    position: relative;
    margin: 0 0 15px;
    padding-bottom: 15px;
    padding-left: 2.5em;
    padding-right: 35px;
    border-bottom: 1px solid #edefef;
    font-size: rem(15);
    &:before {
      position: absolute;
      //margin-right: 0.5em;
      top: 15px;
      left: 0;
      font-size: rem(25);
      color: #dcdcdc;
      content: counter(count, decimal-leading-zero);
      vertical-align: middle;
      line-height: 0;
    }
    &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -8px;
      content: "＋";
      font-size: 30px;
      color: #00c3b6;
      font-weight: normal;
      line-height: 0;
    }
    &.active {
      &:after {
        content: "－";
      }
    }
  }

@include media(sp) {

}
}

.-faq {
  .content__inner.-column .section {
    padding-top: 90px;
  }
  .faq {
    &-section {
      color: #666;
    }
    &-title {
      margin-bottom: 0;
      padding: 20px 35px 20px 0;
      font-weight: normal;
      border-top: 1px solid #edefef;
      border-bottom: 0;
      &:before {
        display: none;
      }
    }
    &-text {
      margin: 0 0 20px;
      padding: 20px 30px;
      background: #f5f9f9;
    }
  }
@include media(sp) {
  .content__inner.-column .section {
    padding-top: 8%;
  }
}
}
