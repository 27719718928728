@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-info
/  --------------------------------------------------------------*/

.top-info {
  padding: 70px 0;
  background: #D2E6EF;
  &__list {
    margin: 45px 0 0;
    padding: 30px;
    background: #F5F5F5;
    dt {
      float: left;
      clear: left;
      margin-right: 1em;
    }
    dd {
      margin-bottom: 15px;
      padding-bottom: 15px;
      background-image: linear-gradient(to right, #000, #000 1px, transparent 1px, transparent 4px);
      background-size: 5px 1px;
      background-position: bottom;
      background-repeat: repeat-x;
      &:last-child {
        margin: 0;
        padding: 0;
        background: none;
      }
    }
  }
}