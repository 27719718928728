@charset "utf-8";

/* ----------------------------------------------------------------
   thema/form
/  --------------------------------------------------------------*/

.form {
  &-section {
    padding: 40px 70px;
    background: #fff;
    border-radius: 14px;
  }
  &-required {
    display: inline-block;
    margin-left: 1em;
    padding: 5px 10px;
    background: #ff0078;
    color: #fff;
    font-size: rem(12);
    line-height: 1;
  }
  &-table {
    width: 100%;
  }
  th,td {
    padding: 15px 0;
  }
  th {
    width: 30%;
    font-weight: normal;
    vertical-align: top;
    text-align: left;
  }
@include media(sp) {
  &-section {
    padding: 20px;
  }
  th,td {
    display: block;
    padding: 0 0 10px;
  }
  th {
    width: 100%;
    padding: 0 0 10px;
  }
}
}

.form-item-text {
  width: 70%;
  box-sizing: border-box;
  padding: 6px 8px;
  background: #e3eaea;
  border: 2px solid #e3eaea;
  &:focus {
    outline: 0;
    border-color: #00c3b4;
    background: #fff;
  }
  @include media(sp) {
    width: 100%;
    margin-bottom: 20px;
  }
  &.-zip {
    width: 5em;
    & + .-zip {
      width: 7em;
    }
  }
}

.form-item-textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 6px 8px;
  background: #e3eaea;
  border: 2px solid #e3eaea;
  resize: vertical;
  &:focus {
    outline: 0;
    border-color: #00c3b4;
    background: #fff;
  }
}

.form-btn {
  margin-top: 15px;
  padding-top: 30px;
  text-align: center;
  border-top: 1px solid #e2e2e2;
}

.btn-submit {
  width: 250px;
  padding: 20px 60px;
  background: #5f91cc url(../img/share/icon_link_fff.png) 92% center no-repeat;
  background-size: 18px 11px;
  border: 0;
  font-size: rem(16);
  color: #fff;
  line-height: 1;
  &.-reset {
    background: #0fb586 url(../img/share/icon_reset.png) 92% center no-repeat;
    background-size: 22px 21px;
  }
  &.-back {
    position: relative;
    display: inline-block;
    background: #0fb586;
    box-sizing: border-box;
    &:before {
      position: absolute;
      top: 50%;
      left: 20px;
      display: inline-block;
      content: "";
      width: 18px;
      height: 11px;
      margin-top: -5.5px;
      background: url(../img/share/icon_link_fff.png) 92% center no-repeat;
      background-size: 18px 11px;
      transform: scale(-1, 1);
    }
  }
  &:hover {
    cursor: pointer;
    opacity: .7;
  }
  & + .btn-submit {
    margin-left: 30px;
  }
@include media(sp) {
  width: 100%;
  margin: 0 0 10px;
  & + .btn-submit {
    margin: 0;
  }
}
}

.form-message-area {
  margin-bottom: 40px;
  &.-error {
    .title-l4-3 {
      &:before {
        background: #f50000;
      }
    }
    .att-list {
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        color: #f50000;
        &:before {
          content: "※";
        }
      }
    }
  }
  &.-thanks {
    .title-l4-3 {
      margin-bottom: 40px;
    }
    .form-btn {
      margin-top: 40px;
      a {
        width: auto;
      }
    }
  }
}

.form-select {
  position: relative;
  select {
  	-webkit-appearance: none;
  	-moz-appearance: none;
  	appearance: none;
  	border-color: #e2e2e2;
  	padding: 6px 20px;
  	padding-right: 60px;
  	background: url(../img/share/arrow_select.png) right center no-repeat;
  	backgroudn-size: cover;
    &:focus {
      outline: 0;
      border-color: #00c3b4;
    }
    &::-ms-expand {
      display: none;
    }
  }
}
.-contact {
  .-main {
    padding: 8% 0;
    background: #f1f1f1;
  }
}
