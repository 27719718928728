@charset "utf-8";

/*----------------------------------------------------
	sub
----------------------------------------------------*/
.sub {
  margin-bottom: 90px;
  padding-top: 60px;
  border-top: 5px solid #09308a;
  a {
    color: #3b5b82;
  }
  &__inner.-fixed {
    width: 25%;
    height: 100%;
    overflow: auto;
  }
  &-title {
    position: relative;
    font-size: rem(16);
    margin: 0;
    padding: 15px 13%;
    border-bottom: 1px solid #dce6e6;
    &:after {
      position: absolute;
      right: 13%;
      top: 50%;
      margin-top: -12px;
      font-family: FontAwesome;
      content: "\f107";
      font-size: 24px;
      line-height: 1;
    }
  }
  &-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    //height: 100%;
    //overflow: scroll;
    &.-fixed {
      width: 25%;
      //position: fixed;
      //top: 0;
    }
    li {
      a {
        display: block;
        padding: 15px 13%;
        //border-left: 10px solid #fff;
        &.active {
          border-left: 10px solid #09308a;
        }
      }
      &.active {
        a {
          border-left: 10px solid #09308a;
        }
      }
      &:nth-child(even) {
        background: #dce6e6;
        //border-left: 10px solid #fff;
      }
    }
  }
  &.-nav-fixed {
    position: fixed;
    padding-top: 0;
    top: 0;
    backface-visibility: hidden;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  &.-nav-stop {
    position: absolute;
  }
// sp
@include media(sp) {
  margin-bottom: 0;
  padding-top: 0;
  &-title {
    padding: 15px;
    &:after {
      right: 15px;
    }
  }
  &-menu {
    li {
      a {
        padding: 15px;
      }
    }
  }
}

}

.-archive {
.sub {
  height: 100%;
  background: #f5f9f9;
  box-sizing: border-box;
  &-title {
    &:after {
      color: #3b5b82;
    }
  }
  &-menu {
    margin-bottom: 70px;
  }
@include media(sp) {
  &-menu {
    margin-bottom: 0;
  }
}
}
}
