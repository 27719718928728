@charset "utf-8";

/* ----------------------------------------------------------------
   component/ttl
/  --------------------------------------------------------------*/


.title-l2 {
  position: relative;
  width: 88%;
  margin: 90px auto 60px;
  box-sizing: border-box;
  font-size: rem(22);
  color: #4b698d;
  span {
    display: inline-block;
    position: relative;
    padding: 0 70px 0 64px;
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      content: " ";
      display: inline-block;
      width: 3.5em;
      height: 3px;
      margin-top: -5px;
      background: #4b698d;
      transform: rotate(-60deg);
      vertical-align: middle;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
@media screen and (min-width: 1440px) {
  width: 1200px;
}
@include media(sp) {
  width: auto;
  margin: 40px auto;
  font-size: rem(18);
  text-align: center;
}

}

.-column {
  .title-l2 {
    width: 88%;
  }
}

.section {
  .title-l2 {
    width: auto;
  }
}

.title-l3 {
  position: relative;
  margin-bottom: 20px;
  padding: 0 0 5px 30px;
  color: #4b698d;
  font-size: rem(17);
  border-bottom: 3px solid #4b698d;
  &:before {
    position: absolute;
    display: inline-block;
    content: " ";
    bottom: -3px;
    left: -1px;
    width: 19px;
    height: 11px;
    background: url(../img/share/title_arrow.png) no-repeat;
  }
@include media(sp) {
  font-size: rem(16);
}
}

.section .title-l3 {
  margin-top: 70px;
  margin-left: -3.5%;
@include media(sp) {
  margin-top: 50px;
}
}

.title-l4 {
  margin: 30px 0 0.5em;
  font-size: rem(14);
  &:before {
    content: "■";
  }
  &-2 {
    margin: 30px 0 0;
    font-size: rem(14);
    &:before {
      content: "□";
    }
  }
  &-3 {
    position: relative;
    margin: 30px 0 10px;
    padding: 0 0 5px;
    color: #5a5a5a;
    font-size: rem(15);
    &:before {
      position: absolute;
      bottom: 0;
      display: inline-block;
      content: "";
      width: 50px;
      height: 3px;
      background: #00a0ec;
    }
  }
}

.title-no-style {
  margin: 0;
  font-size: inherit;
  &.-normal {
    font-weight: normal;
  }
}