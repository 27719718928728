@charset "utf-8";

/* ----------------------------------------------------------------
   component/list
/  --------------------------------------------------------------*/

ol {
  &.-decimal-type01 {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    position: relative;
    counter-reset: number;
    & > li {
      padding-left: 2.5em;
      &:before {
        width: 2em;
        text-align: right;
        position: absolute;
        left: 0;
        display: inline-block;
        counter-increment: number;
        content: "(" counter(number) ")";
      }
    }
  }
  &.-kana {
    margin-top: 0;
    list-style: katakana;
  }
  &.no-style {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
  }
}

ul {
  &.note {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #edefef;
      padding-left: 1.5em;
      text-indent: -1.5em;
      &:before {
        content: "・";
        margin-right: 0.5em;
      }
      a {
        color: #8c8c8c;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &.no-style {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
  }
}


.link-list {
  &.-pdf {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    li {
      margin-bottom: 15px;
      a {
        display: inline-block;
        padding: 8px 15px 8px 30px;
        color: #f50000;
        line-height: 1;
        border: 1px solid #f50000;
        border-radius: 25px;
        &:after {
          content: "↓";
          margin-left: 1em;
        }
      }
    }
  }
}