@charset "utf-8";

/* ----------------------------------------------------------------
   theme/archive-entry
/  --------------------------------------------------------------*/

.archive-entry {
  padding-left: 20px;
  .title-l2 {
    margin-left: -40px;
    font-size: rem(20);
  }
  .title-l3 {
    padding: 0;
    border: 0;
    margin-left: 0;
    &:before {
      bottom: 0;
    }
    &:after {
      display: block;
      width: 150px;
      content: "";
      border-bottom: 3px solid #4b698d;
    }
    span {
      padding: 0 100px 5px 30px;
      display: inline-block;
    }
  }
  .title-l4 {
    margin-bottom: 20px;
    font-size: rem(17);
    color: #5a5a5a;
    &:before {
      display: none;
    }
    &:after {
      display: block;
      width: 50px;
      content: " ";
      margin-top: 5px;
      border-bottom: 3px solid #00a0ec;
    }
  }
  .title-l5 {
    margin-bottom: 20px;
    font-size: rem(16);
    padding: 10px 0;
    border-top: 1px solid #dce6e6;
    border-bottom: 1px solid #dce6e6;
    color: #5a5a5a;
  }
  .title-l6 {
    margin-bottom: 20px;
    font-size: rem(16);
    padding-left: 10px;
    border-left: 3px solid #00a0ec;
    color: #5a5a5a;
  }
  .title-l7 {
    margin-bottom: 20px;
    font-size: rem(16);
    padding: 10px;
    background: #f5f9f9;
    color: #5a5a5a;
  }
  dl {
    dt {
      float: left;
      clear: left;
      margin: 0;
      padding: 0;
    }
    dd {
      margin: 0;
      padding: 0;
    }
  }
  p {
    margin-bottom: 2em;
  }
  table {
    width: 100%;
    margin-bottom: 2em;
    border-collapse: collapse;
    th,td {
      padding: 10px 15px;
      
    }
    th {
      width: 20%;
      color: #fff;
      background: #53719d;
      border-bottom: 1px solid #fff;
      font-weight: normal;
    }
    td {
      border-bottom: 1px solid #c7c7c7;
    }
    tr:last-child th {
      border-bottom: 1px solid #53719d;
    }
  }
  ul {
    margin: 0 0 2em;
    padding: 0;
    list-style: none;
    li {
      padding: 10px 0;
      padding-left: 1em;
      border-bottom: 1px solid #edefef;
      &:before {
        content: "・";
        margin-left: -1em;
      }
    }
    &.btn-list {
      display: flex;
      justify-content: space-between;
      li {
        border: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  ol {
    list-style: none;
    margin: 0 0 2em;
    padding: 0;
    counter-increment: count;
    li {
      position: relative;
      padding: 10px 0;
      padding-left: 2.5em;
      border-bottom: 1px solid #edefef;
      counter-increment: counter;
      &:before {
        position: absolute;
        top: 22px;
        left: 0;
        font-size: rem(25);
        color: #dcdcdc;
        content: counter(counter, decimal-leading-zero);
        vertical-align: middle;
        line-height: 0;
      }
    }
  }
  .link-file {
    a {
      position: relative;
      display: inline-block;
      padding: 10px;
      min-width: 150px;
      border-width: 1px;
      border-style: solid;
      border-radius: 25px;
      text-align: center;
      &:after {
        position: absolute;
        right: 10px;
        display: inline-block;
        content: "↓";
      }
      &[href$=".pdf"] {
        color: #f50000;
      }
      &[href$=".xlsx"],
      &[href$=".xlsm"],
      &[href$=".xls"] {
        color: #006837;
      }
      &[href$=".doc"],
      &[href$=".docm"],
      &[href$=".dox"] {
        color: #004abd;
      }
    }
  }
  .img-area {
    clear: both;
    overflow: hidden;
  }
  ul.archive-entry-nav {
    list-style: none;
    margin: 0;
    padding: 50px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dce6e6;
    li {
      padding: 0;
      border: 0;
      &:before {
        display: none;
      }
      a {
      }
    }
    .back {
      a {
        color: #5a5a5a;
        display: flex;
        align-items: center;
        &:before {
          margin-right: 10px;
          font: normal normal normal 14px/1 FontAwesome;
          content: "\f105";
          font-size: 40px;
          line-height: 1;
        }
      }
    }
    .prev {
      a {
        display: flex;
        align-items: center;
        color: #284e84;
        &:after {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          margin-left: 10px;
          font: normal normal normal 14px/1 FontAwesome;
          content: "\f104";
          font-size: 40px;
          text-align: center;
          line-height: 1;
          border: 1px solid #284e84;
          border-radius: 4px;
        }
      }
    }
    .next {
      a {
        display: flex;
        align-items: center;
        color: #284e84;
        &:before {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          margin-right: 10px;
          font: normal normal normal 14px/1 FontAwesome;
          content: "\f105";
          font-size: 40px;
          text-align: center;
          line-height: 1;
          border: 1px solid #284e84;
          border-radius: 4px;
        }
      }
    }
  }
@include media(sp) {
  padding: 0;
  .title-l2 {
    margin-left: 0;
  }
  .btn-list {
    flex-direction: column;
  }
  .link-file {
    a {
      display: block;
    }
  }
  table {
    th,td {
      display: block;
      border: 0;
    }
    th {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
}
