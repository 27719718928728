@charset "utf-8";

/* ----------------------------------------------------------------
   layout/footer
/  --------------------------------------------------------------*/

.footer {
  padding: 60px 0 0;
  background: #00C3B4;
  color: #fff;
  a {
    color: #fff;
  }
  &__info {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
  }
  &__logo {
    margin: 0 35px 0 0;
    line-height: 0;
    width: 388px;
  }
  &__address {
    margin: 0;
    font-size: rem(14);
  }
  &__tel {
    font-size: rem(20);
  }
  &__nav {
    font-size: rem(14);
    text-align: center;
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-right: 10px;
        &:after {
          content: "／";
          margin-left: 15px;
        }
        &:last-child:after {
          display: none;
        }
      }
    }
  }
@include media(sp) {
  padding-top: 20px;
  &__info {
    display: block;
    margin: 20px 0;
    padding: 0 10px;
    text-align: center;
  }
  &__logo {
    width: 194px;
    margin: 0 auto 20px;
  }
}
}

.copyright {
  margin-top: 25px;
  padding: 25px 0;
  background: #231815;
  font-size: rem(12);
  color: #fff;
  text-align: center;
}
