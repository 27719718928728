@charset "utf-8";

/* ----------------------------------------------------------------
   theme/feat
/  --------------------------------------------------------------*/
.-feat {
  main {
    padding-bottom: 0;
  }
  .page-title {
    position: relative;
    &:after {
      position: absolute;
      display: block;
      content: " ";
      width: 400px;
      height: 400px;
      left: 18%;
      top: 50%;
      margin-top: -200px;
      background: url(../img/feat/catch_img.png) no-repeat;
      background-size:100%;
      z-index: 200;
    }
  }
  .content {
    background: #D2E6EF;
  }
  .-main {
    position: relative;
  }
  .sub {
  @include media(pc) {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    padding: 0;
    &-menu {
      display: inline-block;
      width: auto;
      box-shadow: 0px 0px 10px 3px #ddd;
      &.-fixed {
        right: 0;
      }
      li {
        background: none;
        border-bottom: 1px solid #fff;
        a {
          padding: 15px;
          padding-left: 40px;
          padding-right: 20px;
          background: #00C3B4;
          color: #fff;
          font-weight: bold;
          &.-place {
            background-image: url(../img/feat/icon01.png);
            background-repeat: no-repeat;
            background-position: 15px center;
          }
          &.-faq {
            background-image: url(../img/feat/icon02.png);
            background-repeat: no-repeat;
            background-position: 15px center;
          }
        }
        &:last-child {
          border: 0;
        }
      }
    }
  }
  }
@include media(sp) {
  .page-title {
    &:after {
      width: 52.5px;
      height: 91.5px;
      left: auto;
      right: 3%;
      bottom: 4.5em;
    }
  }
}
}

.feat-section {
  padding: 5px 0 100px;
  background: #E5ECEC;
  overflow: hidden;
  &:nth-child(even) {
    background: #fff;
  }
  &:first-child {
    background: #D2E6EF;
  }
  &__title {
    font-size: rem(21);
    font-weight: normal;
  }
  * + &__title {
    margin-top: 55px;
  }
  &__comment-title {
    display: inline-block;
    position: relative;
    margin: 0 0 20px;
    margin-left: 190px;
    padding: 35px;
    background: #fff;
    border-radius: 48%;
    z-index: 100;
    font-weight: normal;
    font-size: rem(22);
    &:before {
    	content: "";
    	position: absolute;
    	bottom: -30px;
    	left: 30px;
    	margin-top: -15px;
    	border: 10px solid transparent;
    	border-top: 50px solid #fff;
    	z-index: 0;
    	transform: rotate(45deg);
    }
    & + .feat-section__title {
      margin-top: 0;
    }
  }
  &__img {
    //line-height: 1;
  }
  &.-feat01 {
    position: relative;
    .media-section__detail {
      padding-right: 350px;
    }
    .feat-section__img {
      position: absolute;
      right: -25%;
      top: 65px;
      text-align: right;
      small {
        padding-right: 100px;
      }
    }
  }
  &.-feat02 {
    .media-section__detail {
      width: 55%;
      float: left;
    }
    .feat-section__img {
      float: right;
      margin-top: 5em;
    }
  }
  &.-feat03 {
    position: relative;
    .contents-inner {
      position: static;
    }
    .media-section__detail {
      margin-left: 22%;
    }
    .feat-section__img {
      width: 22%;
      position: absolute;
      left: 0;
      top: 45%;
      text-align: right;
    }
  }
  &.-feat04 {
    .media-section__detail {
      float: left;
      width: 65%;
    }
    .feat-section__img {
      float: right;
    }
  }
  &.-feat05 {
    position: relative;
    .contents-inner {
      position: static;
    }
    .media-section__detail {
      margin-left: 200px;
    }
    .feat-section__img {
      margin-top: 80px;
      margin-right: -200px;
      text-align: right;
    }
    .-img-r {
      position: absolute;
      left: 0;
      top: 0;
      text-align: right;
    }
  }
  &.-feat06 {
    .media-section {
      overflow: initial;
    }
    .media-section__detail {
      float: right;
      width: 55%;
    }
    .feat-section__img {
      position: relative;
      margin-left: -130px;
      float: left;
      text-align: right;
    }
    .media-section.-img-r {
      clear: both;
      .media-section__detail {
        float: left;
        width: 70%;
      }
      .feat-section__img {
        position: relative;
        margin-right: -300px;
        float: right;
        text-align: left;
      }
    }
  }
@media screen and (max-width: 1024px) {
  &.-feat01 {
    position: relative;
    .media-section__detail {
      padding-right: 0;
    }
    .feat-section__img {
      position: static;
      text-align: center;
      margin: 2em 0;
    }
  }
  &.-feat05 {
    position: relative;
    .media-section__detail {
      margin-left: 30%;
    }
    .feat-section__img {
      margin-top: 80px;
      margin-right: 0;
    }
    .-img-r {
      width: 30%;
    }
  }
  &.-feat06 {
    .media-section.-img-r {
      clear: both;
      .media-section__detail {
        width: 60%;
      }
      .feat-section__img {
        margin-right: -200px;
      }
    }
  }
}
@include media(tab) {
  padding: 1px 0 50px;
  &__comment-title {
    margin-left: 0;
    margin-top: 20px;
  }
  &.-feat02 {
    .media-section__detail {
      width: auto;
      float: none;
    }
    .feat-section__img {
      float: none;
      text-align: center;
    }
  }
  &.-feat03 {
    .media-section__detail {
      margin-left: 0;
    }
    .feat-section__img {
      width: auto;
      position: static;
    }
  }
  &.-feat04 {
    .media-section__detail {
      width: auto;
      float: none;
    }
    .feat-section__img {
      float: none;
    }
  }
  &.-feat05 {
    .media-section__detail {
      margin-left: 0;
    }
    .feat-section__img {
      margin-top: 0;
      margin-right: 0;
    }
    .-img-r {
      position: static;
      width: auto;
    }
  }
  &.-feat06 {
    .media-section__detail {
      float: none;
      width: auto;
    }
    .feat-section__img {
      float: none;
      margin-left: 0;
    }
    .media-section.-img-r {
      .media-section__detail {
        float: none;
        width: auto;
      }
      .feat-section__img {
        margin-right: 0;
        float: none;
      }
    }
  }
}
}
