@charset "utf-8" ;

/* ----------------------------------------------------------------
    core/base
/  --------------------------------------------------------------*/

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

body {
  //color: #717071;
  color: #000;
  //font-family: "Yu Gothic", YuGothic,'Montserrat',sans-serif;
  font-family: 'Noto Sans Japanese', Sans-Serif;
  line-height: 1.6;
  font-size: 16px;
}

a {
  color: #65AADD;
  color: #F0844A;
  text-decoration: none;
  &:hover {
    opacity: .7;
  }
}

img {
  max-width: 100%;
}

p {
  margin: 0 0 1em;
}

[data-show="sp"] {
  @include media(pc) {
    display: none;
  }
}

dl,
ul,
ol {
  margin-top: 0;
}

