@charset "utf-8";

/* ----------------------------------------------------------------
   theme/visit
/  --------------------------------------------------------------*/


.-inner {
  main {
    margin-bottom: 100px;
    color: #8c8c8c;
    font-size: rem(14);
    line-height: 2;
    .googlemap {
      margin: 30px 0;
    }
  }
@include media(sp) {
  main {
    margin-bottom: 50px;
  }
}
}

.visit-about {
  .-break {
    clear: both;
  }
  dt {
    margin-right: 1em !important;
  }
  dd dd {
    clear: both;
    margin-left: 1em;
  }
  .title-l4-2 {
    margin: 0;
  }
}

.bus-timetable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  th {
    padding: 15px;
    background: #53719d;
    color: #fff;
    font-weight: normal;
    border-right: 2px solid #fff;
  }
  td {
    padding: 15px;
    background: #d1eefc;
    text-align: center;
    border-right: 2px solid #fff;
  }
  tr:nth-child(odd) {
    td {
      background: #ebf8fe;
    }
  }
@include media(sp) {
  font-size: rem(12);
  td,th {
    padding: 5px;
  }
}
}

.map-link {
  text-align: right;
  a {
    display: inline-block;
    padding: 8px 15px 8px 30px;
    border: 1px solid #5a5a5a;
    border-radius: 25px;
    color: #5a5a5a;
  }
}

.table {
  border-collapse: collapse;
  th {
    width: 20%;
    padding: 10px 20px;
    background: #53719d;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #fff;
    font-weight: normal;
  }
  td {
    padding: 10px 20px;
    border-bottom: 1px solid #c7c7c7;
  }
  tr:last-child {
    th {
      border-color: #53719d;
    }
  }
  a {
    color: #8c8c8c;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
@include media(sp) {
  th,
  td {
    display: block;
    width: auto;
    border: 0;
    padding: 10px;
  }
  td {
    padding-bottom: 20px;
  }
}
}
