@charset "utf-8";

/* ----------------------------------------------------------------
   layout/main
/  --------------------------------------------------------------*/

main {
  padding-bottom: 90px;
  dl {
    overflow: hidden;
    dt {
      float: left;
      clear: left;
    }
    dd {
      margin: 0;
      padding: 0;
    }
  }
@include media(sp) {
  padding: 0;
}
}

.-top .contents-title {
  width: auto;
  margin: 0 0 20px;
  padding: 0;
  font-size: rem(28);
  @include media(sp) {
    text-align: center;
  }
  & &__ruby {
    margin-left: 0;
    font-size: rem(14);
    @include media(sp) {
      display: inline-block;
      margin-left: 0.5em;
    }
  }
}


.main-column {
  position: relative;
  padding: 0 20px;
  @include media(pc) {
    padding: 0;
  }
  &.-col2 {
    @include media(pc) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__catch {
    font-size: rem(22);
    font-weight: normal;
    margin-top: 0;
    @include media(pc) {
      flex: 0 0 310px;
      padding-right: 40px;
      font-size: rem(28);
    }
  }
  &-data {
    @include media(pc) {
      flex: 0 0 630px;
    }
  }
}

.data-section {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px dotted #231815;
  font-size: rem(15);
  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border: 0;
  }
  & &__title {
    margin: 0 0 20px;
    font-size: rem(24);
  }
}

.comming-soon {
  padding-top: 30px;
  text-align: center;
  @include media(pc) {
    display: table-cell;
    width: 1000px;
    height: 30vh;
    padding: 0;
    vertical-align: middle;
  }
}
