@charset "utf-8";

/* ----------------------------------------------------------------
   component/contents-title
/  --------------------------------------------------------------*/

.contents-title {
  position: relative;
  margin: 70px 0;
  font-size: rem(24);
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  span {
    position: relative;
    z-index: 100;
  }
  & &__ruby {
    display: inline-block;
    margin-top: 20px;
    font-size: rem(16);
    font-weight: normal;
  }
  &:before {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    content: " ";
    display: block;
    width: 480px;
    width: 40.8%;
    height: 2px;
    margin-left: -20.4%;
    background: #E3E3E4;
    transform: rotate(-8deg);
  }
  &.-type-f:before {
    background: #fff;
  }
@include media(sp) {
  &:before {
    top: 50%;
    left: 50%;
    width: 100%;
    margin-left: -50%;
  }
}

}
