@charset "utf-8";

/* ----------------------------------------------------------------
   component/link
/  --------------------------------------------------------------*/

.link {
  &:after {
    display: inline-block;
    content: " ";
    width: 20px;
    height: 12px;
    margin-left: 5px;
    margin-top: -1px;
    background: url(../img/share/icon_link.png) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
@include media(sp) {
  &:after {
    width: 10px;
    height: 6px;
  }
}
}
 .link-file {
  a {
    position: relative;
    display: inline-block;
    padding: 8px 30px;
    line-height: 1;
    min-width: 200px;
    border-width: 1px;
    border-style: solid;
    border-radius: 25px;
    text-align: center;
    color: #f50000;
    &:after {
      position: absolute;
      right: 10px;
      display: inline-block;
      content: "↓";
    }
    &[href$=".pdf"] {
      color: #f50000;
    }
    &[href$=".xlsx"],
    &[href$=".xlsm"],
    &[href$=".xls"] {
      color: #006837;
    }
    &[href$=".doc"],
    &[href$=".docm"],
    &[href$=".dox"] {
      color: #004abd;
    }
  }
}