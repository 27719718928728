@charset "utf-8";

/* ----------------------------------------------------------------
   theme/main_visual
/  --------------------------------------------------------------*/


body {
  position: relative;
}

#youtube {
  //width: 100vw;
  //height: 100vh;
  //position: absolute;
  left: 0px;
  top: -35.2188px;
  .ytp-watermark,
  .ytp-cards-button-icon,
  .ytp-chrome-top-buttons,
  .ytp-large-play-button,
  .ytp-button,
  button {
    display: none !important;
    opacity: 0 !important;
  }
}

.main-visual-img {
  position: absolute;
  opacity: 0;
  top: 0;
}

.main-visual {
  position: relative;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  margin-top: -158px;
  line-height: 0;
  //padding: 75% 0 0;
  //box-sizing: border-box;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &-news {
    display: flex;
    position: absolute;
    bottom: 35px;
    right: 25px;
    a {
      display: flex;
      color: #000;
    }
    &__title {
      display: flex;
      align-items: center;
      width: 8%;
      margin: 0;
      padding: 0 20px;
      background: #00C3B4;
      color: #fff;
      font-size: rem(13);
      border-radius: 50px 0 0 50px;
      line-height: 1;
      font-weight: normal;
      text-align: center;
      box-sizing: border-box;
    }
    &__text {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      background: #fff;
      border-radius: 0 50px 50px 0;
      line-height: 1.2;
      width: 92%;
      box-sizing: border-box;
      p {
        margin: 0;
      }
    }
  }
@include media(tab) {
  margin: 0;
}

@include media(sp) {
  margin: 0;
  &-news {
    position: absolute;
    margin: 0 10px;
    bottom: 20px;
    right: auto;
    &__title {
      width: 24%;
      padding: 0 10px;
      font-size: rem(12);
    }
    &__text {
      width: 76%;
      padding: 10px;
      font-size: rem(12);
    }
  }
}
}