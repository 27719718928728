@charset "utf-8";

/* ----------------------------------------------------------------
   component/page-title
/  --------------------------------------------------------------*/

.page-title {
  display: flex;
  position: relative;
  margin: 0;
  height: 600px;
  background: url(../img/share/catch.jpg) right center no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  &__detail {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 20%;
    padding-left: 3%;
    background: #00C3B4;
    &:after {
      position: absolute;
      display: block;
      content: " ";
      top: 0;
      right: -200px;
      width: 120px;
      border-bottom: 600px solid #00C3B4;
      border-right: 200px solid transparent;
      height: 0;
      z-index: -1;
    }
    @media screen and (max-width:1024px) {
      width: 40%;
    }
  }
  &__text {
    position: relative;
    font-size: rem(34);
    &:before {
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 0;
      content: " ";
      display: block;
      width: 230px;
      height: 1px;
      background: #000;
      transform: rotate(-8deg);
    }
  }
  &__ruby {
    padding-top: 30px;
    font-size: rem(12);
  }
  &__description {
    margin: 30px 0 0;
    font-size: rem(16);
    color: #fff;
    font-weight: bold;
  }
@include media(sp) {
  align-items: flex-end;
  height: auto;
  padding-top: 300px;
  background-size: cover;
  background-position: right top;
  &__detail {
    display: block;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    &:after {
      display: none;
    }
  }
  &__text {
    display: block;
    font-size: rem(24);
    &:before {
      width: 50%;
      left: 10px;
    }
  }
  &__description {
    margin: 0 15px 15px 0;
    font-size: rem(14);
    font-weight: normal;
  }
}
}

// 各ページ画像設定
.-visit {
  .page-title {
    background-image: url(../img/visit/catch.jpg);
    @include media(sp) {
      background-image: url(../img/visit/catch_sp.jpg);
    }
  }
}
.-faq2 {
  .page-title {
    background-image: url(../img/faq/catch.jpg);
    @include media(sp) {
      background-image: url(../img/faq/catch_sp.jpg);
    }
  }
}
.-introduction {
  .page-title {
    background-image: url(../img/introduction/catch.jpg);
    @include media(sp) {
      background-image: url(../img/introduction/catch_sp.jpg);
    }
  }
}
.-exhibition {
  .page-title {
    background-image: url(../img/exhibition/catch.jpg);
    @include media(sp) {
      background-image: url(../img/exhibition/catch_sp.jpg);
    }
  }
}
.-gallery {
  .page-title {
    background-image: url(../img/gallery/catch.jpg);
    @include media(sp) {
      background-image: url(../img/gallery/catch_sp.jpg);
    }
  }
}
.-collection {
  .page-title {
    background-image: url(../img/collection/catch.jpg);
    @include media(sp) {
      background-image: url(../img/collection/catch_sp.jpg);
    }
  }
}
.-schedule {
  .page-title {
    background-image: url(../img/schedule/catch.jpg);
    @include media(sp) {
      background-image: url(../img/schedule/catch_sp.jpg);
    }
  }
}
.-event {
  .page-title {
    background-image: url(../img/event/catch.jpg);
    @include media(sp) {
      background-image: url(../img/event/catch_sp.jpg);
    }
  }
}
.-about {
  .page-title {
    background-image: url(../img/about/catch.jpg);
    @include media(sp) {
      background-image: url(../img/about/catch_sp.jpg);
    }
  }
}
.-contact {
  .page-title {
    background-image: url(../img/contact/catch.jpg);
    @include media(sp) {
      background-image: url(../img/contact/catch_sp.jpg);
    }
  }
}
.-feat {
  .page-title {
    background-image: url(../img/feat/catch.jpg);
    @include media(sp) {
      background-image: url(../img/feat/catch_sp.jpg);
    }
  }
}
.-place {
  .page-title {
    background-image: url(../img/place/catch.jpg);
    @include media(sp) {
      background-image: url(../img/place/catch_sp.jpg);
    }
  }
}
.-faq {
  .page-title {
    background-image: url(../img/feat/catch_faq.jpg);
    @include media(sp) {
      background-image: url(../img/feat/catch_faq_sp.jpg);
    }
  }
}
.-children {
  .page-title {
    background-image: url(../img/children/catch.jpg);
    @include media(sp) {
      background-image: url(../img/children/catch_sp.jpg);
    }
  }
}
.-shop {
  .page-title {
    background-image: url(../img/shop/catch.jpg);
    @include media(sp) {
      background-image: url(../img/shop/catch_sp.jpg);
    }
  }
}
.-link {
  .page-title {
    background-image: url(../img/link/catch.jpg);
    @include media(sp) {
      background-image: url(../img/link/catch_sp.jpg);
    }
  }
}
.-applications {
  .page-title {
    background-image: url(../img/applications/catch.jpg);
    @include media(sp) {
      background-image: url(../img/applications/catch_sp.jpg);
    }
  }
}
.-information {
  .page-title {
    background-image: url(../img/information/catch.jpg);
    @include media(sp) {
      background-image: url(../img/information/catch_sp.jpg);
    }
  }
}
.-site {
  .page-title {
    background-image: url(../img/site/catch.jpg);
    @include media(sp) {
      background-image: url(../img/site/catch_sp.jpg);
    }
  }
}
.-document {
  .page-title {
    backgroun-imaged: url(../img/document/catch.jpg);
    @include media(sp) {
      background-image: url(../img/document/catch_sp.jpg);
    }
  }
}
.-note {
  .page-title {
    background-image: url(../img/notebook/catch.jpg);
    @include media(sp) {
      background-image: url(../img/notebook/catch_sp.jpg);
    }
  }
}

.-map {
  .page-title {
    background-image: url(../img/map/catch.jpg);
    @include media(sp) {
      background-image: url(../img/map/catch_sp.jpg);
    }
  }
}

.-children {
  .page-title {
    background-image: url(../img/children/catch.jpg);
    @include media(sp) {
      background-image: url(../img/children/catch_sp.jpg);
    }
  }
}