@charset "utf-8";

/* ----------------------------------------------------------------
   theme/information
/  --------------------------------------------------------------*/


.information {
  padding-top: 90px !important;
  &-list {
    padding: 20px;
    background: #f5f9f9;
    border: 1px solid #dce6e6;
    dt {
      position: relative;
      padding: 20px 25px;
      color: #666;
      z-index: 100;
    }
    dd {
      border-bottom: 1px solid #f5f9f9;
    }
    a {
      position: relative;
      display: block;
      padding: 20px 0;
      padding-right: 50px;
      background: #fff;
      color: #666;
      z-index: 0;
      &:after {
        position: absolute;
        right: 25px;
        top: 20%;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f105";
        font-size: 40px;
        line-height: 1;
      }
      &:hover {
        background: #fbfbfb;
      }
    }
  }
  .pagination {
    border: 0;
  }
@include media(sp) {
  padding-top: 8% !important;
  &-list {
    padding: 10px;
    dt {
      float: none;
      padding-left: 10px;
    }
    dd {
      padding-left: 0;
    }
    a {
      padding: 15px;
      padding-right: 45px;
      &:after {
        top: 50%;
        right: 15px;
        margin-top: -15px;
        font-size: 30px;
      }
    }
  }
}
}
