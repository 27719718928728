@charset "utf-8";

/* ----------------------------------------------------------------
   theme/country
/  --------------------------------------------------------------*/
.-country {
@include media(pc) {

.header {
  padding-bottom: 30px;
}

.header-menu {
  width: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.header-logo {
  margin-top: 30px;
}

.header-info {
  display: flex;
  padding-top: 20px;
  padding-right: 30px;
  &__access {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #231815;
  }
  &__contact {
    margin-right: 15px;
  }
  .-tel {
    font-size: rem(20);
  }
  .-mail {
    font-size: rem(14);
  }
}

}

.catch {
  width: 100%;
  text-align: center;
  line-height: 1;
  img {
    max-width: none;
    width: 100%;
  }
}


}

.c-header-section {
  position: relative;
  padding: 130px 0;
  background: #EBF0F0;
  .contents-inner {
    position: static;
    display: flex;
    align-items: center;
  }
  &__text {
    width: 50%;
  }
  &__img {
    position: absolute;
    left: 55%;
  }
@include media(sp) {
  padding: 20px 0;
  .contents-inner {
    display: block;
  }
  &__text {
    width: auto;
  }
  &__img {
    position: static;
  }
}
}

.c-about-section {
  .contents-inner {
    display: flex;
    position: relative;
    align-items: center;
    &:after {
      position: absolute;
      display: block;
      bottom: -200px;
      right: -200px;
      content: " ";
      width: 399px;
      height: 399px;
      background: url(../img/country/bg_img01.png) no-repeat;
    }
  }
  &__text {
    order: 2;
    margin-bottom: 50px
  }
  &__img {
    order: 1;
    flex: 0 0 50%;
    margin: 0;
  }
@include media(sp) {
  .contents-inner {
    display: block;
    &:after {
      width: 200px;
      height: 200px;
      background-size: contain;
      bottom: auto;
      top: -10px;
      left: 50%;
      margin-left: -100px;
    }
  }
  &__img {
    margin-bottom: 20px;
  }
}
}

.c-facility {
  padding: 50px 0 0;
  overflow: hidden;
  background: #F0F0EB;
  text-align: center;
  &-section {
    margin-bottom: 150px;
    &__title {
      display: inline-block;
      margin: 0 0 30px;
      padding: 20px 25px;
      border: 1px solid #000;
      line-height: 1;
    }
    &__img {
      margin-top: 60px;
      margin-bottom: 90px;
      text-align: left;
      .-img02 {
        position: absolute;
        left: 340px;
        top: 120px;
      }
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      text-align: left;
      &-text {
        position: relative;
        padding: 30px 40px;
        background: rgba(255,255,255,.8);
        box-sizing: border-box;
        z-index: 100;
      }
      &-img {
        positin: relative;
        z-index: 0;
      }
    }
    &.-section01 {
      .c-facility-section__item-text {
        width: 400px;
      }
      .-item01 {
        margin-bottom: 75px;
        padding-bottom: 85px;
        .c-facility-section__item-text {
          position: absolute;
          width: 400px;
          bottom: 0;
        }
        .c-facility-section__item-img {
          width: 100%;
          text-align: right;
        }
      }
      .-item02 {
        margin-bottom: 20px;
        .c-facility-section__item-text {
          order: 2;
          margin-left: -20px;
          margin-top: -30px;
        }
        .c-facility-section__item-img {
          order: 1;
        }
      }
      .-item03 {
        padding-bottom: 25px;
        justify-content: flex-end;
        .c-facility-section__item-text {
          position: absolute;
          bottom: 0;
          right: 470px;
        }
      }
    }
    &.-section02 {
      .contents-inner {
        overflow: visible;
      }
      .c-facility-section__detail {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 30px 40px;
        background: rgba(255,255,255,.8);
        box-sizing: border-box;
        z-index: 100;
      }
      .c-facility-section__item {
        display: block;
        width: 31%;
      }
    }
  }
@include media(sp) {
  padding: 20px 0;
  &-section {
    margin-bottom: 50px;
    &__img {
      margin: auto;
      .-img02 {
        position: static;
      }
    }
    &__item {
      flex-direction: column;
      &-text {
        order: 2;
        padding: 20px;
      }
      &-img {
        order: 1;
      }
    }
    &.-section01 {
      .c-facility-section__item-text {
        width: auto;
      }
      .-item01 {
        margin-bottom: 30px;
        padding-bottom: 0;
        .c-facility-section__item-text {
          position: static;
          width: auto;
        }
        .c-facility-section__item-img {
          width: 100%;
          text-align: right;
        }
      }
      .-item02 {
        margin-bottom: 30px;
        .c-facility-section__item-text {
          margin: 0;
        }
      }
      .-item03 {
        padding-bottom: 0;
        .c-facility-section__item-text {
          position: static;
        }
      }
    }
    &.-section02 {
      .c-facility-section__detail {
        display: block;
      }
      .c-facility-section__item {
        width: auto;
      }
      .c-facility-section__item-title {
        margin-bottom: 5px;
      }
    }
  }
}
}

.c-materials {
  background-image: url(../img/country/bg_img02_01.png),url(../img/country/bg_img02_02.png),url(../img/country/bg_img02_03.png),url(../img/country/bg_img02_04.png);
  background-position: right top, left 20%, right 85%, 10% bottom;
  background-repeat: no-repeat;
  &__description {
    text-align: center;
    margin-bottom: 50px;
  }
  &__item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
    padding: 0 75px;
    &-img {
      width: 100%;
      margin-right: 60px;
    }
    &-text {
      width: 100%;
    }
    &.-wrap {
      flex-wrap: wrap;
      justify-content: flex-end;
      .c-materials__item-text {
        width: 46.5%;
      }
    }
  }
@include media(sp) {
  background-size: 120px auto;
  &__item {
    flex-direction: column;
    padding: 0;
    &-img {
      margin-right: 0;
    }
    &.-wrap {
      .c-materials__item-text {
        width: auto;
      }
    }
  }
}
}