@charset "utf-8";

/*----------------------------------------------------
  lib
----------------------------------------------------*/
@import "scss/lib/normalize";

/*----------------------------------------------------
  core
----------------------------------------------------*/
@import "scss/core/function";
@import "scss/core/mixin";
@import "scss/core/base";

/*----------------------------------------------------
  layout
----------------------------------------------------*/
@import "scss/layout/content";
@import "scss/layout/header";
@import "scss/layout/main";
//@import "scss/layout/back2top";
@import "scss/layout/footer";
@import "scss/layout/sub";

/*----------------------------------------------------
  component
----------------------------------------------------*/
@import "scss/component/contents_title";
@import "scss/component/link";
@import "scss/component/media";
@import "scss/component/ttl";
@import "scss/component/list";
@import "scss/component/breadcrumb";
@import "scss/component/page_title";

/*----------------------------------------------------
  theme
----------------------------------------------------*/
@import "scss/theme/normalize";
@import "scss/theme/top_nav";
@import "scss/theme/top_news";
@import "scss/theme/top_event";
@import "scss/theme/top_info";
@import "scss/theme/top_collection";
@import "scss/theme/top_bnr";
@import "scss/theme/main_visual";
@import "scss/theme/access";
@import "scss/theme/map";
@import "scss/theme/document";
@import "scss/theme/visit";
@import "scss/theme/faq";
@import "scss/theme/event";
@import "scss/theme/introduction";
@import "scss/theme/information";
@import "scss/theme/shop";
@import "scss/theme/collection";
@import "scss/theme/about";
@import "scss/theme/form";
@import "scss/theme/feat";
@import "scss/theme/children";

@import "scss/theme/archive";
@import "scss/theme/archive_nav";
@import "scss/theme/archive_entry";

@import "scss/theme/country";

/*----------------------------------------------------
  utilities
----------------------------------------------------*/
@import "scss/utility/text";
