@charset "utf-8";

/* ----------------------------------------------------------------
   component/breadcrumb
/  --------------------------------------------------------------*/


.breadcrumb {
  position: relative;
  z-index: 100;
  display: flex;
  list-style: none;
  width: 88%;
  margin: 0 auto;
  padding: 20px 0;
  li {
    a {
      color: #000;
    }
    &:after {
      margin: 0 0.5em;
      font-family: FontAwesome;
      content: "\f105";
      color: #8c8c8c;
    }
    &:last-child:after {
      display: none;
    }
  }
  &.-column {
    position: absolute;
    left: 0;
    padding: 18px 25px;
    z-index: 100;
  }
  &.-type-f {
    li:after {
      color: #fff;
    }
  }
@include media(sp) {
  display: none;
}
@media screen and (min-width: 1440px) {
  width: 1000px;
}
}
