@charset "utf-8" ;

/* ----------------------------------------------------------------
   layout/content
/  --------------------------------------------------------------*/

.wrapper {
  overflow: hidden;
}

.content {
  width: 100%;
  &__inner {
    position: relative;
    z-index: 99;
    &.-column {
      width: 100%;
      display: flex;
      aside {
        position: relative;
        z-index: 99;
        order: 1;
        flex: 0 0 25%;
      }
      main {
        order: 2;
        flex: 0 0 75%;
      }
      .section {
        width: 88%;
        margin: 0 auto 30px;
        box-sizing: border-box;
        padding: 0 3%;
      }
    }
  }
@include media(sp) {
  &__inner {
    position: relative;
    &.-column {
      display: block;
      .section {
        padding: 0;
      }
    }
  }
}

}


.contents-inner {
  position: relative;
  width: 88%;
  margin: 0 auto;
  @include media(sp) {
    width: 88%;
  }
  @media screen and (min-width: 1440px) {
    width: 1000px;
  }
}

.contents-link {
  &.-detail {
    text-align: right;
    a {
      color: #231815;
    }
  }
}



