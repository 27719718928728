@charset "utf-8";

/* ----------------------------------------------------------------
   theme/document
/  --------------------------------------------------------------*/


.-document {
  .content__inner {
    display: flex;
  }
  aside {
    position: relative;
    z-index: 99;
    order: 1;
    background: #D2E6EF;
  }
  .sub {
    border: 0;
    &-menu {
      & > li {
        border-bottom: 2px solid #fff;
        a {
          display: flex;
          color: #000;
          font-weight: bold;
          align-items: center;
          padding: 0;
        }
        &:nth-child(even) {
          background: none;
        }
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 62px;
          height: 62px;
          margin-right: 1em;
        }
        &.active {
          border: 0;
          a {
            background: #fff;
            border: 0;
          }
          .number {
            background: #00C3B4;
          }
        }
      }
    }
  }
@include media(sp) {
  .content__inner {
    display: block;
  }
  aside {
    display: none;
  }
}
}

.document-section {
  position: relative;
  padding: 0 0 100px;
  overflow: hidden;
  &__number {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: rem(34);
    line-height: 1;
    background: #000;
    color: #fff;
  }
  &__img {
    margin-bottom: 30px;
  }
  &__text {
    margin: 0;
    padding: 1em 0;
    border-bottom: 1px solid #D2E6EF;
    *:last-child {
      margin-bottom: 0;
    }
  }
  &:nth-child(odd) {
    background: #F2F2F0;
    .contents-title:before {
      background: #fff;
    }
  }

@include media(tab) {

}

@include media(sp) {
  padding-bottom: 50px;
  .contents-title {
    margin: 50px 0;
  }
  &__number {
    width: 40px;
    height: 40px;
    font-size: rem(24);
  }
  &__img {
    margin-bottom: 1em;
  }
}
}

.paginate {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 100px 0;
  padding: 0;
  li {
    padding: 0 50px;
    a {
      position: relative;
      display: flex;
      align-items: center;
      color: #000;
    }
    &.prev {
      a {
        &:before {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 0.5em;
          background: #00C3B4;
          content: " ";
          border-radius: 4px;
        }
        &:after {
          position: absolute;
          top: 10px;
          left: 7px;
          display: inline-block;
          content: "";
          border: 9px solid transparent;
          border-right: 8px solid #fff;
        }
      }
    }
    &.next {
      a {
        display: flex;
        justify-content: flex-end;
        &:after {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-left: 0.5em;
          background: #00C3B4;
          content: " ";
          border-radius: 4px;
        }
        &:before {
          position: absolute;
          top: 10px;
          right: 5px;
          display: inline-block;
          content: "";
          border: 9px solid transparent;
          border-left: 8px solid #fff;
        }
      }
    }
  }
@include media(sp) {
  margin: 50px 0;
  li {
    width: 50%;
    padding: 0 20px;
  }
}
}
