@charset "utf-8";

/* ----------------------------------------------------------------
   theme/feat
/  --------------------------------------------------------------*/

.-children {
main {
  padding-bottom: 0;
}
.section {
  overflow: hidden;
  text-align: center;
  padding-bottom: 50px;
  .contents-inner {
    width: 88%;
    @media screen and (min-width: 1440px) {
      width: 1013px;
    }
  }
  &-detail {
    //display: none;
    margin-top: 80px;
  }
  &-btn {
    margin-top: 50px;
    &:hover {
      cursor: pointer;
    }
  }
  .media-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      margin-bottom: 50px;
      flex: 0 0 460px;
      text-align: left;
      &__img {
        img {
          border: 10px solid #fff;
          border-radius: 10px;
          box-sizing: border-box;
        }
      }
    }
  }
  &.-ryoma {
    background: #EDED8A;
    .section-description {
      &:after {
        position: absolute;
        top: -20px;
        right: -20px;
        display: block;
        content: " ";
        width: 228px;
        height: 226px;
        background: url(../img/children/img_ryoma01.png) no-repeat;
        
      }
    }
    .section-detail {
      position: relative;
      &:after {
        position: absolute;
        bottom: -75px;
        left: -200px;
        display: block;
        content: " ";
        width: 151px;
        height: 271px;
        background: url(../img/children/img_ryoma02.png) no-repeat;
      }
    }
    .ryoma-history-title {
      margin: 0;
      margin-top: 80px;
      margin-bottom: -80px;
    }
    .ryoma-history-list {
      display: flex;
      align-items: center;
      padding-left: 97px;
      height: 333px;
      text-align: left;
      background: url(../img/children/bg_ryoma.png) no-repeat;
      background-size: 100% auto;
      ol {
        margin: 0;
        margin-top: -20px;
        padding: 0;
        list-style: none;
        column-count: 2;
        column-fill: balance;
        line-height: 2;
      }
      li {
        
      }
      a {
        color: #000;
      }
    }
  }
  &.-about {
    background: #DAEFED;
    .section-detail {
      background: url(../img/children/img_about01.png) right top no-repeat;
    }
  }
  &.-quiz {
    position: relative;
    overflow: visible;
    padding-top: 1px;
    background: #EDCFBC;
    &:before {
      position: absolute;
      display: block;
      content: "";
      top: -60px;
      left: 10%;
      width: 490px;
      height: 242px;
      background: url(../img/children/img_quiz01.png) no-repeat;
    }
    .quiz-btn {
      margin-top: 50px;
    }
  }
  &-title {
    margin: 55px 0 20px;
  }
@include media(sp) {
  .media-section {
    display: block;
  }
  &.-ryoma {
    .section-description {
      &:after {
        top: 190px;
        right: 0;
        width: 114px;
        height: 114px;
        background-size: contain;
        
      }
    }
    .section-detail {
      &:after {
        bottom: -140px;
        left: 0;
        width: 75.5px;
        height: 135.5px;
        background-size: contain;
      }
    }
    .ryoma-history-title {
      margin: 0 0 10px;
    }
    .ryoma-history-list {
      padding-left: 0;
      height: auto;
      background: none;
      ol {
        margin: 0;
        list-style: none;
        column-count: auto;
      }
    }
  }
  &.-about {
    .section-detail {
      background: url(../img/children/img_about01.png) right top no-repeat;
      background-size: 75px 114px;
    }
  }
  &.-quiz {
    position: relative;
    overflow: visible;
    padding-top: 1px;
    background: #EDCFBC;
    &:before {
      position: absolute;
      display: block;
      content: "";
      top: -60px;
      left: 5%;
      width: 245px;
      height: 121px;
      background: url(../img/children/img_quiz01.png) no-repeat;
      background-size: contain;
    }
  }
}
}

.ryoma-media {
  padding-bottom: 50px;
  background: #EDED8A;
  .contents-inner {
    display: flex;
  }
  &-section {
    margin-bottom: 50px;
    &__img {
      width: 100%;
      margin-right: 50px;
    }
    &__title {
      margin-bottom: 10px;
      font-size: rem(36);
      line-height: 1.2;
      span {
        font-size: rem(24);
      }
    }
    &__text {
      width: 100%;
    }
    &:nth-child(even) {
      .ryoma-media-section__img {
        order: 1;
        margin-left: 50px;
      }
      .ryoma-media-section__text {
        order: 0;
      }
    }
    &:last-child {
      .ryoma-media-section__img {
        order: 0;
        width: 45%;
      }
      .ryoma-media-section__text {
        order: 1;
        width: auto;
      }
    }
  }
  #ryoma01 {
    .contents-inner {
      background: url(../img/children/bg_ryoma03.png) right top no-repeat;
    }
    .ryoma-media-section__img {
      margin-right: 60px;
    }
  }
  #ryoma02 {
    padding-top: 30px;
    background: url(../img/children/bg_ryoma04.png) 10% top no-repeat;
    .ryoma-media-section__img {
      margin-left: 25px;
    }
  }
  #ryoma04 {
    background: url(../img/children/bg_ryoma06.png) 15% top no-repeat;
    .ryoma-media-section__img {
      margin-left: 10px;
    }
  }
  #ryoma05 {
    margin-top: -150px;
    padding-top: 150px;
    background: url(../img/children/bg_ryoma07.png) 85% top no-repeat;
    .ryoma-media-section__img {
      text-align: right;
    }
  }
  #ryoma06 {
    background: url(../img/children/bg_ryoma08.png) 20% top no-repeat;
  }
  #ryoma07 {
    background: url(../img/children/bg_ryoma09.png) 80% top no-repeat;
    .ryoma-media-section__img {
      text-align: center;
    }
  }
  #ryoma08 {
    margin-bottom: 0;
    background: url(../img/children/bg_ryoma10.png) 20% top no-repeat;
  }
@include media(sp) {
  .contents-inner {
    display: flex;
    flex-direction: column;
  }
  &-section {
    margin-bottom: 30px;
    &__img {
      margin-right: 0;
      order: 1;
      text-align: center;
    }
    &__title {
      font-size: rem(24);
      span {
        font-size: rem(16);
      }
    }
    &__text {
      order: 0;
    }
    &:nth-child(even) {
      .ryoma-media-section__img {
        order: 1;
        margin-left: 0;
      }
      .ryoma-media-section__text {
        order: 0;
      }
    }
    &:last-child {
      .ryoma-media-section__img {
        order: 1;
      }
      .ryoma-media-section__text {
        order: 0;
      }
    }
  }
  #ryoma01 {
    .contents-inner {
      background-size: 98px 177px;
    }
    .ryoma-media-section__img {
      margin-right: 0;
    }
  }
  #ryoma02 {
    padding-top: 15px;
    background-size: 218px 94.5px;
    .ryoma-media-section__img {
      margin-left: 0;
    }
  }
  #ryoma04 {
    background-size: 138px 98.5px;
    .ryoma-media-section__img {
      margin-left: 0;
    }
  }
  #ryoma05 {
    margin-top: 0;
    padding-top: 0;
    background-size: 224.5px 115.5px;
  }
  #ryoma06 {
    background-size: 120px 114.5px;
  }
  #ryoma07 {
    background-size: 114.5px;
  }
  #ryoma08 {
    background: url(../img/children/bg_ryoma10.png) left top no-repeat;
    background-size: 94px 95px;
    .ryoma-media-section__img {
      width: 100%;
    }
  }
}
}



}