@charset "utf-8";

/* ----------------------------------------------------------------
   theme/shop
/  --------------------------------------------------------------*/


.shop-order {
  margin-bottom: 90px;
}
.shop-order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5a5a5a;
  padding: 30px 0;
  border-top: 1px solid #dce6e6;
  border-bottom: 1px solid #dce6e6;
  & + .shop-order-item {
    border-top: 0;
  }
  &__title {
    flex: 1;
    margin: 0;
    font-size: rem(18);
  }
  &__detail {
    color: #8c8c8c;
    font-size: rem(14);
    font-weight: normal;
  }
  &__price {
    margin: 0;
    padding-right: 2em;
  }
  &__select {
    select {
    	-webkit-appearance: none;
    	-moz-appearance: none;
    	appearance: none;
    	border: none;
    	padding: 6px 40px;
    	padding-right: 80px;
    	background: #ececec url(../img/share/arrow_select.png) right center no-repeat;
    	backgroudn-size: cover;
    	border-radius: 6px;
    	text-align: center;
      &:focus {
        outline: 0;
      }
      &::-ms-expand {
        display: none;
      }
    }
  }
@include media(sp) {
  display: block;
  &__price {
    margin: 10px 0;
    padding: 0;
    text-align: right;
  }
  &__select {
    text-align: right;
  }
}
}

.-shop {
  .form-section {
    background: #f7f7f7;
  }
  .form-item-text,
  .form-item-textarea {
    border: 1px solid #e2e2e2;
    background: #fff;
  }
  .form-select select {
    background-color: #fff;
  }
}

