@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-nav
/  --------------------------------------------------------------*/

.top-nav {
  display: flex;
  &__item {
    flex: 1;
    flex: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 3%;
    padding-right: 2%;
    background: #036EAC;
    color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    &:first-child {
      background: #00C3B4;
      padding-left: 3.5%;
    }
    &:last-child {
      padding: 0;
      background: #70C9E0;
      align-items: center;
    }
  }
  &__text {
    margin: 0;
    padding-left: 15px;
    border-left: 1px solid #fff;
  }
  &__title {
    margin: 0 0 20px;
    font-size: rem(21);
  }
  &__link {
    margin: 0;
    line-height: 1;
    a {
      display: inline-block;
      color: #fff;
      &:before {
        content: " ";
        display: inline-block;
        width: 20px;
        height: 7px;
        margin-right: 7px;
        background: #00C3B4;
        transform: rotate(-8deg);
        position: relative;
        top: -3px;
      }
    }
  }
  &__item:first-child {
    .top-nav__link a:before {
      background: #fff;
    }
  }
  &__list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 25px;
      padding-left: 0;
      padding-right: 32%;
      border-bottom: 1px solid #fff;
      font-size: rem(18);
      text-align: right;
      &:last-child {
        border: 0;
      }
    }
    a {
      color: #fff;
      &:after {
        display: inline-block;
        content: " ";
        width: 20px;
        height: 12px;
        margin-left: 5px;
        margin-top: -1px;
        background: url(../img/share/icon_link_fff.png) no-repeat;
        background-size: contain;
        vertical-align: middle;
      }
    }
  }
  &__img {
    position: absolute;
    right: 10px;
    bottom: -70%;
    width: 133px;
    width: 29%;
  }
@include media(tab) {
  &__item {
    display: block;
    padding-bottom: 3em;
  }
  &__title {
    font-size: rem(18);
  }
  &__link {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    li {
      height: 50%;
      padding: 10px;
      font-size: rem(16);
      text-align: left;
      a {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
}
@include media(sp) {
  
  display: block;
  &__title {
    font-size: rem(16);
  }
  &__link {
    font-size: rem(12);
  }
  &__text {
    width: 100px;
    font-size: rem(11);
  }
  &__img {
    bottom: -20px;
    width: 20%;
  }
  &__list {
    li {
      padding: 15px;
      padding-left: 3%;
      font-size: rem(14);
      text-align: left;
      a {
        &:after {
          width: 10px;
          height: 6px;
          margin-top: 5px;
        }
      }
    }
  }
}

}