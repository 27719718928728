@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-news
/  --------------------------------------------------------------*/

.top-news {
  padding: 50px 0;
  background: #F5F5F5;
  .contents-inner {
    padding: 7px;
    background: #fff url(../img/top/bg_info.png);
    background-size: 1%;
    border: 2px solid #FF0078;
  }
  &__detail {
    position: relative;
    display: flex;
    align-content: center;
    padding: 25px;
    font-size: rem(24);
    font-weight: bold;
    background: #fff;
    color: #FF0078;
    a {
      display: flex;
      color: #FF0078;
    }
  }
  &__icon {
    display: inline-block;
    width: 45px;
    height: 45px;
    margin-right: 25px;
    line-height: 1.8;
    text-align: center;
    border-radius: 50%;
    background: #FF0078;
    color: #fff;
  }
  &__text {
    margin: 0;
    line-height: 1.8;
  }
  &__img {
    position: absolute;
    width: 162px;
    bottom: 10px;
    right: 10px;
  }
@include media(sp) {
  padding: 4% 0;
  &__detail {
    padding: 4%;
    font-size: rem(18);
  }
  &__icon {
    flex: 0 0 30px;
    height: 30px;
    margin-right: 5%;
    line-height: 1.5;
  }
  &__text {
    margin-bottom: 35px;
    line-height: 1.5;
  }
}

}
