@charset "utf-8" ;

// ----------------------------------------------------------------
// function.scss - Event.scss
// -----------------------------------------------------------------

///
// media
// @param string
// @return media query and content
///
@mixin media($bp) {

  @if $bp == 'sp' {
    @media screen and (max-width: 767px) {
      @content;
    }
  }

  @else if $bp == 'tab' {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
  
  @else if $bp == 'mid' {
    @media screen and (min-width: 768px) {
      @content;
    }
  }

  @else if $bp == 'pc' {
    @media screen and (min-width: 960px) {
      @content;
    }
  }

}
