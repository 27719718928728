@charset "utf-8";

/* ----------------------------------------------------------------
   theme/archive-nav
/  --------------------------------------------------------------*/

.archive-nav {
  bottom: auto;
  &__title {
    position: relative;
    margin: 0;
    padding: 10px;
    padding-right: 15px;
    background: #00a0ec;
    text-align: center;
    line-height: 1;
    color: #fff;
    font-weight: normal;
    font-size: rem(14);
  }
  &__list {
    display: none;
    margin: 0;
    padding: 0;
    //padding: 10px;
    list-style: none;
    line-height: 2;
    background: #fff;
    border: 1px solid #00a0ec;
    li {
      text-align: center;
      border-bottom: 1px solid #00a0ec;
      &:last-child {
        border: 0;
      }
      a {
        color: #00a0ec;
        &:hover {
          color: #00a0ec;
        }
      }
    }
  }
@include media(pc) {
  display: inline-block;
  position: absolute;
  top: -5em;
  right: 0;
  bottom: auto;
  display: flex;
  &__item {
    width: 9em;
    margin-left: 5px;
  }
  &__title {
      &:after {
        position: absolute;
        right: 10px;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f0d7";
      }
      &:hover {
        cursor: pointer;
      }
  }
}

@include media(sp) {
  &__list {
    display: block;
    padding: 0;
    border: 0;
    li {
      text-align: left;
      border-bottom: 1px solid #00a0ec;
      &:last-child {
        border-bottom: 0;
      }
      a {
        display: block;
        padding: 10px;
      }
    }
  }
}
}
