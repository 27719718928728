@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-bnr
/  --------------------------------------------------------------*/

.top-bnr {
  padding: 60px 0;
  background: #70C9E0;
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    width: 62.5%;
    margin: 0 auto;
    padding: 0;
    li {
      width: 48%;
      margin-bottom: 2.5%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
@include media(sp) {
  padding: 15px;
  &__list {
    width: auto;
    flex-direction: column;
    li {
      width: auto;
    }
  }
}
}

.bnr-museum-shop {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  color: #000;
  &__img {
    position: relative;
    width: 63.2%;
    line-height: 0;
    &-title {
      position: absolute;
      left: 2.5%; // 15px;
      top: 7%; // 22px;
      width: 108px;
      width: 17%;
    }
  }
  &__info {
    width: 36.8%;
    text-align: center;
    box-sizing: border-box;
    font-weight: bold;
  }
  &__title {
    margin: 0;
    margin-bottom: 40px;
    line-height: 0;
    &:after {
      position: relative;
      z-index: 0;
      bottom: -21px;
      content: " ";
      display: block;
      width: 75%;
      height: 2px;
      margin: auto;
      background: #E3E3E4;
      transform: rotate(-8deg);
    }
    img {
      position: relative;
      z-index: 100;
      width: 224px;
      width: 61%;
    }
  }
  &__note {
    font-size: rem(12);
  }
  .link {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    padding-right: 20px;
    border-bottom: 4px solid #00C3B4;
  }
}

.top-bnr-list {
  &__list {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 60px 0 30px;
    padding: 0;
    li {
      width: 272px;
      width: 17%;
      line-height: 0;
      margin: 0;
      margin-right: 17px;
      &:last-child {
        margin: 0;
      }
    }
  }
@include media(sp) {
  &__list {
    margin: 20px 10px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  li {
    width: 48%;
    margin: 0 0 10px;
  }
}
}